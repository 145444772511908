.about-content{
    height: calc(100vh - 80px);
    width: calc(100vw);
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    color: black;
}
.about-content-text{
    width: 45%;
    text-align: left;
}
.about-content-text>p{
    font-size: 1.15rem;
}
.about-content-image{
    width: 30%;
    margin-bottom: 90px;
}

.about-img{
    /* height: 100%; */
    /* width: 100%; */
    /* background-color: rgb(44, 74, 184); */
    /* margin-left: 200px; */
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 10px;
}


.home-scroll-down{
    margin-top: 30px;
    
}
.gradient-background{
    background-image: linear-gradient(90deg, rgb(137, 27, 241), rgb(191, 132, 223));
    background-size: 100%;
    
    /* -webkit-background-clip: text; */
    /* -moz-background-clip: text; */
    -webkit-text-fill-color: white; 
    -moz-text-fill-color: white;
}
.gradient-text{
    background-image: linear-gradient(90deg, rgb(137, 27, 241), rgb(191, 132, 223));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}