.sidebar{
    background-color: #fff;
    height: calc(100vh - 80px);
    width:100px;
    position:fixed;
    top:80px;
    left:0;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
a{
    text-decoration: none;
    color: #333;
}

.social{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
}

.social>*{
    margin: 10px;
}

.professional{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;
}
.professional>*{
    margin: 10px;
}
