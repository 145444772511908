.nav-container{
    background-color:#fff;
    height: 80px;
    width: 100vw;
    position:sticky;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content:space-between;
}

.nav-icon{
    color: black;
    font-size: 2.5rem;
    cursor: pointer;
    margin-left: 50px;
    font-family: cursive, sans-serif;
}

.nav-links{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 60%;
    height: 100%;
}

.nav-links>a{
    color: black;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    font-family: cursive, sans-serif;
    cursor: pointer;
}

.active{
    background-image: linear-gradient(90deg, rgb(137, 27, 241), rgb(191, 132, 223));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}