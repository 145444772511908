.home-container{
    background-color: white;
    height: calc(100vh - 80px);
    width: calc(100vw);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;

}

.home-content{
    display: flex;
    align-items: center;
    justify-content: center;

}

.home-content-text{
    width: 30%;
    text-align: left;
}
.home-content-text>p{
    font-size: 1.15rem;
}
.home-content-image{
    width: 30%;
    margin-bottom: 90px;
    
}

/* .home-img{
    height: 100%;
    width: 100%;
    background-color: purple;
    margin-left: 200px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 10px;
  } */
.home-scroll-down{
    margin-top: 80px;
    
}
.gradient-background{
    background-image: linear-gradient(90deg, rgb(137, 27, 241), rgb(191, 132, 223));
    background-size: 100%;
    
    /* -webkit-background-clip: text; */
    /* -moz-background-clip: text; */
    -webkit-text-fill-color: white; 
    -moz-text-fill-color: white;
}
.gradient-text{
    background-image: linear-gradient(90deg, rgb(137, 27, 241), rgb(191, 132, 223));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}